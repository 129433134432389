import * as React from "react"
import '../../gatsby-browser.js';
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { motion } from "framer-motion"
import axios from "axios";
import {url} from "../url/url";
import Load from '../static/img/load/load.gif'
import { Link } from "gatsby"
// import ReactHtmlParser from "react-html-parser";
import BlogBanner from "../static/img/Blogs-banner.svg"
import Business from "../static/img/BlogIcons/business.svg"
import Tech from "../static/img/BlogIcons/tech.svg"
import IT from "../static/img/BlogIcons/IT.svg"
import Nature from "../static/img/BlogIcons/nature.svg"
import Story from "../static/img/BlogIcons/story.svg"
import News from "../static/img/BlogIcons/news.svg"
import MetaTags from "../component/MetaTags.jsx";


class Blogs extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            cate:[],
            loading: true,
            catLoading: true,
            category:[],
        }

    }


    // =================== get blog data =====================
    componentDidMount() {

        this.getCate();

        if(typeof window !== 'undefined') {
            axios({
                method: 'GET',
                url: url + '/blogs?_locale=' + localStorage.getItem('lan'),
            })
                .then((result) => {
                    // console.log(result.data)
                    this.setState({
                        data: result.data,
                        loading: false
                    })
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    })
                })
        } else if(typeof window == 'undefined') {
            axios({
                method: 'GET',
                url: url + '/blogs?_locale=' + "en",
            })
                .then((result) => {
                    // console.log(result.data)
                    this.setState({
                        data: result.data,
                        loading: false
                    })
                })
                .catch((error) => {
                    this.setState({
                        loading: false
                    })
                })
        }


    }

    // ============================================================

    // ================= get categories ====================

    getCate = () => {
        axios({
            method:'GET',
            url: url+'/categories',
        })
            .then((result) => {
                // console.log(result.data)
                this.setState({
                    cate: result.data,
                    catLoading: false
                })
            })
            .catch((error) => {
                this.setState({
                    catLoading: false
                })
            })
    }
    // ======================================================

    getCat = (e) => {
        this.setState({
            category: e.target.value
        })

    }


    render(){
        const pageVariants = {
            initial: {
                opacity: 0,
            },
            in: {
                opacity: 1,
            },
            out: {
                opacity: 0,
            },
        }

        const { data, loading, cate, catLoading, category } = this.state;

        return(
            <>

                <Navbar />

                <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                >
                    <section id="blogs" className="blogs">
                        <div align="center">
                            <h1 className="highlighted-text">Our <span className="light-text">Blogs</span></h1>
                            <img className="contact-banner" src={BlogBanner} alt="" />
                        </div>
                    </section>
                    <section id="services" className="blogs-container">
                        <div className="row">
                    <div className="tech-container " data-wow-duration="1.4s">
                                            <div className="box">
                                                <img src={Business} alt="" />
                                                <h4 className="title">Business</h4>
                                               
                                            </div>
                 
                                            <div className="box">
                                            <img src={IT} alt="" />
                                                <h4 className="title">IT</h4>
                                               
                                            </div>
                                            <div className="box">
                                            <img src={News} alt="" />
                                                <h4 className="title">News</h4>
                                               
                                            </div>
                                        
             
                                            <div className="box">
                                            <img src={Nature} alt="" />
                                                <h4 className="title">Nature</h4>
                                               
                                        
                                        </div>
              
                                            <div className="box">
                                            <img src={Tech} alt="" />
                                                <h4 className="title">Tech</h4>
                                               
                                            </div>
                   
                                            <div className="box">
                                                <img src={Story} alt="" />
                                                <h4 className="title">Story</h4>
                                               
                                           
                                        </div>
                                        </div>
                                     
                   
                    </div>
                    </section>
                    {/* <div className="container">
                        <div className="row ">
                            <div className="col-sm-3 categories_lists">
                                <h3 className={"cate_title"}> Categories </h3>

                                <div className="form-group">
                                    <select className="form-control" id="exampleFormControlSelect2" onChange={this.getCat}>
                                        <option value={""}> Select Categories </option>

                                        {catLoading ? (
                                            <>
                                                <div align="center" className="mt-5 mb-5">
                                                    <img src={Load} />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {cate.map((item, index) => {
                                                    return(
                                                        <>
                                                            <option value={item.id} > {item.name} </option>
                                                        </>
                                                    )
                                                })}

                                            </>
                                        )}
                                    </select>
                                </div>

                                <br/><br/>
                            </div>

                            <div className="col-sm-9">
                                {loading ? (
                                    <>
                                        <div align="center" className="mt-5 mb-5">
                                            <img src={Load} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">

                                            {data.map((item, index) => {

                                                if(category.length == 0){
                                                    return(
                                                        <>
                                                            <div className="col-sm-6">
                                                                <div className="card">
                                                                    {url && <img className="card-img-top" src={url+item.prime.url} alt="Card image cap" />}
                                                                    <div className="card-body">
                                                                        <span> <b> Category:</b>  {item?.category?.name}  </span>
                                                                        <Link to={`/blogdetail/`} state={{'id':item.id}} className="blog-title">
                                                                            <h5 >{item.title}</h5>
                                                                        </Link>
                                                                        <p className="card-text">
                                                                            {ReactHtmlParser(`${item.content.substring(0, 100)}...`)}
                                                                        </p>
                                                                        <p className="card-text"><small className="text-muted">Last
                                                                            Created At : {item.createdAt}
                                                                        </small></p>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        </>
                                                    )
                                                } else if( category.length != 0 ){

                                                    if(category == item?.category?.id){
                                                        return(
                                                            <>
                                                                <div className="col-sm-6">
                                                                    <div className="card">
                                                                        {url && <img className="card-img-top" src={url+item?.prime?.url} alt="Card image cap" />}
                                                                        <div className="card-body">
                                                                            <span> <b> Category:</b>  {item.category.name}  </span>
                                                                            <Link to={`/blogdetail/`} state={{'id':item.id}}>
                                                                                <h5 className="card-title blog-title">{item.title}</h5>
                                                                            </Link>
                                                                            <p className="card-text">
                                                                                {ReactHtmlParser(`${item.content.substring(0, 100)}...`)}
                                                                            </p>
                                                                            <p className="card-text"><small className="text-muted">Last
                                                                                Created At : {item.createdAt}
                                                                            </small></p>
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                }
                                            })}

                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div> */}
                    <br />
                    <Footer value={data} />
                </motion.div>
                <MetaTags title={"Blogs | Koderbox"} /> 
            </>
        )
    }
}

export default Blogs